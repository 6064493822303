import { lazy } from "react";
import { AppRoute } from "./interfaces";

const ClientServices = lazy(() => import("client/services/Services"));

const SingleServicePage = lazy(
  () => import("../components/services/SingleServicePage")
);

const ClientTickets = lazy(() => import("client/tickets/ClientTickets"));
const SingleClientTicket = lazy(
  () => import("../components/tickets/SingleTicketPage")
);

const SingleClientChangeTicket = lazy(
  () => import("../components/tickets/SingleChangeTicketPage")
);

const RoutineMaintenance = lazy(
  () => import("client/routine-maintenance/index")
);

const ClientDashBoard = lazy(() => import("client/ClientDashboard"));
const ClientOnboarding = lazy(() => import("client/dashboard"));

const Diagrams = lazy(() => import("../common/ol-diagrams"));

const CLIENT_ROUTES: AppRoute[] = [
  {
    path: "/client",
    element: ClientDashBoard,
  },
  {
    path: "/client/onboarding",
    element: ClientOnboarding,
  },
  {
    path: "/client/services/:service",
    element: SingleServicePage,
  },
  {
    path: "/client/services",
    element: ClientServices,
  },
  {
    path: "/client/tickets",
    element: ClientTickets,
  },
  {
    path: "/client/tickets/:ticket_id",
    element: SingleClientTicket,
  },

  {
    path: "/client/tickets/change/:ticket_id",
    element: SingleClientChangeTicket,
  },

  {
    path: "/client/routine-maintenance",
    element: RoutineMaintenance,
  },
  { path: "/client/network", element: Diagrams },
];

export default CLIENT_ROUTES;
